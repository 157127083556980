#social {
	position: fixed;
	top: 50%;
	left: 30px;
	transform: translate3d(0,-50%,0);
	width: auto;
	height: auto;
	opacity: 0;
	z-index: 99999;
}

.social_button {
	position: relative;
	width: 38px;
	height: 38px;
	margin-bottom: 10px;
	background: rgba(255,255,255,0.1);
	line-height: 38px;
	border-radius: 50%;
	text-align: center;
	font-size: 18px;
	color: rgba(255,255,255,0.8);
	cursor: pointer;
	transition: 0.3s ease-in-out;

	&:hover {
		color: #d24a70;
	}
}

@media only screen and (max-width: 1023px) {
	#social {
		display: none;
	}
}