@import "bootstrap";
@import "navigation";
@import "social";
@import "footer";
@import "animations";

body {
	padding: 0;
	margin: 0;
	background-image: url("../img/bg-main.jpg");
	background-position: center bottom;
	overflow-x: hidden;
}

h1 {
	font-size: 22px;
	font-family: 'Exo', sans-serif;
	font-weight: 600;
	letter-spacing: 0.05em;
	color: rgba(255,255,255,1);
}

/* Intro Page */

#intro {
	position: relative;
	width: 100%;
	height: 100vh;

	.stars {
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%,-50%);
		width: auto;
		height: auto;
	}
}

#intro-container {
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%,-50%);
	width: 900px;
	height: auto;

	#polygons {
		position: relative;
		width: 100%;
		height: 540px;
		margin-bottom: 100px;

		@mixin polygons {
			position: absolute;
			width: auto;
			height: auto;
		}

		.polygon-big {
			@include polygons;
			top: 0;
			left: 90px;
			z-index: 3;
			opacity: 0;
			display: block;
		}

		.polygon-1 {
			@include polygons;
			top: 380px;
			left: 510px;
			z-index: 2;
			opacity: 0;
			display: block;
		}

		.polygon-2 {
			@include polygons;
			top: 435px;
			left: 680px;
			z-index: 1;
			opacity: 0;
			display: block;
		}

		.logo-big {
			@include polygons;
			top: 130px;
			left: 50%;
			transform: translateX(-50%);
			z-index: 4;
			opacity: 0;
			backface-visibility: hidden;
		}

		.polygon-mobile {
			position: absolute;
			width: 599px;
			height: 398px;
			top: -100px;
			left: 50%;
			transform: translateX(-50%) scale(0.6) !important;
			opacity: 0;
			z-index: 1;
			display: none;
		}
	}

	.intro-text {
		position: relative;
		width: 100%;
		height: auto;
		margin: 0 auto;
		font-size: 18px;
		font-family: 'Poppins', sans-serif;
		color: rgba(255,255,255,0.8);
		letter-spacing: 0.05em;
		line-height: 200%;
		text-align: center;
		opacity: 0;
		display: block;
	}
}

@media screen and (min-width: 1024px) and (max-height: 800px){
	#polygons {
		transform: scale(0.8);
	}
	#intro-container .intro-text {
		bottom: 110px;
	}
}

@media screen and (min-height: 801px) and (max-height: 959px){
	#intro-container .intro-text {
		bottom: 70px;
	}
}

.scroll-down {
	position: absolute;
	bottom: 40px;
	left: 50%;
	transform: translateX(-50%);
	width: auto;
	height: auto;
	text-align: center;
	color: #ffffff;
	font-size: 22px;
	opacity: 0;
	animation-name: pulse;
	-webkit-animation-name: pulse;	
	animation-duration: 1.8s;	
	-webkit-animation-duration: 1.8s;
	animation-iteration-count: infinite;
	-webkit-animation-iteration-count: infinite;
	animation-delay: 2.7s;
	-webkit-animation-delay: 2.7s;
    animation-fill-mode: forwards;

	@keyframes pulse {
		0% {
			opacity: 0;	
		}
		50% {
			opacity: 0.8;	
		}
		100% {
			opacity: 0;	
		}				
	}
}

/* /Intro Page */

/* They Mean Well */

#tmw-base {
	position: relative;
	width: 100%;
	height: auto;
	padding-top: 200px;
	backface-visibility: hidden;

	.stars {
		position: absolute;
		top: 0;
		left: 50%;
		transform: translateX(-50%);
		width: auto;
		height: auto;
	}
}

#planet-base {
	position: relative;
	margin: 0 auto;
	width: 920px;
	height: 420px;

	.planet {
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%,-50%);
		width: auto;
		height: auto;
	}

	.shield {
		position: absolute;
		top: 0;
		left: 0;
		width: auto;
		height: auto;
	}

	.tmw-title {
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%,-50%);
		width: auto;
		height: auto;
		opacity: 0;
		z-index: 5;
	}

	.comet-tech {
		position: absolute;
		top: 170px;
		right: 0;
		width: auto;
		height: auto;
		opacity: 0;
	}

	.comet-nature {
		position: absolute;
		top: 25px;
		left: 220px;
		width: auto;
		height: auto;
		opacity: 0;
		transform: translateZ(0) scale(1.0, 1.0);
	}

	.comet-speed {
		position: absolute;
		bottom: 110px;
		left: 0;
		width: auto;
		height: auto;
		opacity: 0;
	}

	.comet-slow {
		position: absolute;
		top: 10px;
		right: 185px;
		width: auto;
		height: auto;
		opacity: 0;
	}

	.comet-lvlup {
		position: absolute;
		bottom: 0;
		right: 180px;
		width: auto;
		height: auto;
		opacity: 0;
	}
}

.section-info {
	width: 900px;
	height: auto;
	margin: 100px auto 0 auto;
	font-size: 18px;
	font-family: 'Poppins', sans-serif;
	color: rgba(255,255,255,0.8);
	letter-spacing: 0.05em;
	line-height: 200%;
	text-align: center;
	opacity: 0;
}

.tmw-buttons-base {
	width: auto;
	height: auto;
	margin: 100px auto 0 auto;
	text-align: center;
	line-height: 50px;
	opacity: 0;
}

.release-date {
	width: 900px;
	height: auto;
	margin: 45px auto 0 auto;
	font-size: 16px;
	font-family: 'Poppins', sans-serif;
	color: rgba(255,255,255,0.2);
	letter-spacing: 0.05em;
	line-height: 200%;
	text-align: center;
	opacity: 0;
}

.btn-box {
	width: auto;
	height: auto;
	margin: 10px 20px;
	display: inline-block;
}

.btn-gplay {
	width: 168px;
	height: 58px;
	margin: 0 auto;
	line-height: 57px;
	text-align: center;
	border-radius: 6px;
	background-image: -moz-linear-gradient( 0deg, rgb(189,69,87) 0%, rgb(109,42,104) 100%);
	background-image: -webkit-linear-gradient( 0deg, rgb(189,69,87) 0%, rgb(109,42,104) 100%);
	background-image: -ms-linear-gradient( 0deg, rgb(189,69,87) 0%, rgb(109,42,104) 100%);
	float: left;
	transition: 0.5s ease;

	&:hover {
		box-shadow: 0px 0px 32px 0px rgba(255, 255, 255, 0.1);
	}
}

.btn-gplay-disabled {
	width: 168px;
	height: 58px;
	margin: 0 auto;
	line-height: 57px;
	text-align: center;
	border-radius: 6px;
	background-image: -moz-linear-gradient( 0deg, rgba(189,69,87,0.3) 0%, rgba(109,42,104,0.3) 100%);
	background-image: -webkit-linear-gradient( 0deg, rgba(189,69,87,0.3) 0%, rgba(109,42,104,0.3) 100%);
	background-image: -ms-linear-gradient( 0deg, rgba(189,69,87,0.3) 0%, rgba(109,42,104,0.3) 100%);
	float: left;
}

.btn-app-store {
	width: 168px;
	height: 58px;
	margin: 0 auto;
	line-height: 57px;
	text-align: center;
	border-radius: 6px;
	background-image: -moz-linear-gradient( 0deg, rgb(189,69,87) 0%, rgb(109,42,104) 100%);
	background-image: -webkit-linear-gradient( 0deg, rgb(189,69,87) 0%, rgb(109,42,104) 100%);
	background-image: -ms-linear-gradient( 0deg, rgb(189,69,87) 0%, rgb(109,42,104) 100%);
	float: left;
	transition: 0.5s ease;

	&:hover {
		box-shadow: 0px 0px 32px 0px rgba(255, 255, 255, 0.1);
	}
}

.btn-app-store-disabled {
	width: 168px;
	height: 58px;
	margin: 0 auto;
	line-height: 57px;
	text-align: center;
	border-radius: 6px;
	background-image: -moz-linear-gradient( 0deg, rgba(189,69,87,0.3) 0%, rgba(109,42,104,0.3) 100%);
	background-image: -webkit-linear-gradient( 0deg, rgba(189,69,87,0.3) 0%, rgba(109,42,104,0.3) 100%);
	background-image: -ms-linear-gradient( 0deg, rgba(189,69,87,0.3) 0%, rgba(109,42,104,0.3) 100%);
	float: left;
}

.btn-amazon {
	width: 168px;
	height: 58px;
	margin: 0 auto;
	line-height: 57px;
	text-align: center;
	border-radius: 6px;
	background-image: -moz-linear-gradient( 0deg, rgb(189,69,87) 0%, rgb(109,42,104) 100%);
	background-image: -webkit-linear-gradient( 0deg, rgb(189,69,87) 0%, rgb(109,42,104) 100%);
	background-image: -ms-linear-gradient( 0deg, rgb(189,69,87) 0%, rgb(109,42,104) 100%);
	float: left;
	transition: 0.5s ease;

	&:hover {
		box-shadow: 0px 0px 32px 0px rgba(255, 255, 255, 0.1);
	}
}

.btn-amazon-disabled {
	width: 168px;
	height: 58px;
	margin: 0 auto;
	line-height: 57px;
	text-align: center;
	border-radius: 6px;
	background-image: -moz-linear-gradient( 0deg, rgba(189,69,87,0.3) 0%, rgba(109,42,104,0.3) 100%);
	background-image: -webkit-linear-gradient( 0deg, rgba(189,69,87,0.3) 0%, rgba(109,42,104,0.3) 100%);
	background-image: -ms-linear-gradient( 0deg, rgba(189,69,87,0.3) 0%, rgba(109,42,104,0.3) 100%);
	float: left;
}

.video {
	position: relative;
	width: 960px;
	height: 540px;
	margin: 100px auto 0 auto;
	border-radius: 6px;
	overflow: hidden;
	opacity: 0;

	.overlay {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;

		.play-btn {
			position: absolute;
			top: 50%;
			left: 50%;
			transform: translate(-50%,-50%);
			width: 60px;
			height: 60px;
			border-radius: 50%;
			background: rgba(0,0,0,0.5);
			font-size: 22px;
			color: rgba(255,255,255,0.8);
			line-height: 60px;
			text-align: center;
			cursor: pointer;
			transition: 0.3s ease-in-out;

			&:hover {
				background: rgba(0,0,0,0.6);
				color: #d24a70;
			}
			i {
				position: relative;
				left: 2px;
			}
		}
	}
	.tmw {
		background: url("../img/thumb-tmw.png");
		background-position: center;
	}
	.som {
		background: url("../img/thumb-som.png");
		background-position: center;
	}
}

/* /They Mean Well */

/* Shards of Memories */

#som-base {
	position: relative;
	width: 100%;
	height: auto;
	padding: 200px 0 100px 0;
}

#shards-base {
	position: relative;
	margin: 0 auto;
	width: 1170px;
	height: 600px;

	.som-logo {
		position: absolute;
		top: 0;
		left: 50%;
		transform: translateX(-50%);
		width: auto;
		height: auto;
		opacity: 0;
	}

	.silhouette {
		position: absolute;
		bottom: 70px;
		left: 50%;
		transform: translateX(-50%);
		width: auto;
		height: auto;
	}

	.shard {
		width: auto;
		height: auto;
	}

	.shard1 {
		position: absolute;
		top: 510px;
		left: 0;
		width: auto;
		height: auto;
		opacity: 2;
	}

	.shard2 {
		position: absolute;
		top: 580px;
		left: 340px;
		width: auto;
		height: auto;
		opacity: 0;
	}

	.shard3 {
		position: absolute;
		top: 865px;
		right: 380px;
		width: auto;
		height: auto;
		opacity: 0;
	}

	.shard4 {
		position: absolute;
		top: 1305px;
		right: 290px;
		width: auto;
		height: auto;
		opacity: 0;
		z-index: 5;
	}

	.shard5 {
		position: absolute;
		top: 660px;
		right: 20px;
		width: auto;
		height: auto;
		opacity: 0;
	}
}

.som-buttons-base {
	width: auto;
	height: auto;
	margin: 100px auto 0 auto;
	text-align: center;
	line-height: 44px;
	opacity: 0;
}

.btn-steam {
	width: 168px;
	height: 58px;
	margin: 0 auto;
	line-height: 57px;
	text-align: center;
	border-radius: 6px;
	background-image: -moz-linear-gradient( 0deg, rgb(189,69,87) 0%, rgb(109,42,104) 100%);
	background-image: -webkit-linear-gradient( 0deg, rgb(189,69,87) 0%, rgb(109,42,104) 100%);
	background-image: -ms-linear-gradient( 0deg, rgb(189,69,87) 0%, rgb(109,42,104) 100%);
	float: left;
	transition: 0.5s ease;

	&:hover {
		box-shadow: 0px 0px 32px 0px rgba(255, 255, 255, 0.1);
	}
}

.btn-steam-disabled {
	width: 168px;
	height: 58px;
	margin: 0 auto;
	line-height: 57px;
	text-align: center;
	border-radius: 6px;
	background-image: -moz-linear-gradient( 0deg, rgba(189,69,87,0.3) 0%, rgba(109,42,104,0.3) 100%);
	background-image: -webkit-linear-gradient( 0deg, rgba(189,69,87,0.3) 0%, rgba(109,42,104,0.3) 100%);
	background-image: -ms-linear-gradient( 0deg, rgba(189,69,87,0.3) 0%, rgba(109,42,104,0.3) 100%);
	float: left;
}

/* /Shards of Memories */

/* Contact Form */

#contact {
	position: relative;
	width: 100%;
	height: auto;
	padding-bottom: 100px;
}

.contact-info {
	width: 900px;
	height: auto;
	margin: 0 auto;
	font-size: 18px;
	font-family: 'Poppins', sans-serif;
	color: rgba(255,255,255,0.8);
	letter-spacing: 0.05em;
	line-height: 200%;
	text-align: center;
	opacity: 0;
}

.contact-form {
	width: 500px;
	height: auto;
	margin: 40px auto 0 auto;
	opacity: 0;

	input {
		width: 100%;
		height: 48px;
		margin-bottom: 20px;
		padding: 0 20px;
		background: none;
		border: none;
		outline: 0;
		border-bottom: 1px solid rgba(255,255,255,0.1);
		font-size: 15px;
		font-family: 'Poppins', sans-serif;
		color: rgba(255,255,255,0.8);
		letter-spacing: 0.05em;
		transition: 0.5s ease;

		&:hover {
			border-bottom: 1px solid rgba(255,255,255,0.8);
		}

		&::placeholder {
			color: rgba(255,255,255,0.3);
		}

		&:focus {
			border-bottom: 1px solid rgba(255,255,255,0.8);
		}
	}

	textarea {
		width: 100%;
		height: 160px;
		margin-bottom: 40px;
		padding: 10px 20px 0 20px;
		background: none;
		border: none;
		outline: 0;
		border-bottom: 1px solid rgba(255,255,255,0.1);
		line-height: 185%;
		font-size: 15px;
		font-family: 'Poppins', sans-serif;
		color: rgba(255,255,255,0.8);
		letter-spacing: 0.05em;
		resize: none;
		transition: 0.5s ease;

		&:hover {
			border-bottom: 1px solid rgba(255,255,255,0.8);
		}

		&::placeholder {
			color: rgba(255,255,255,0.3);
		}

		&:focus {
			border-bottom: 1px solid rgba(255,255,255,0.8);
		}
	}

	.btn-send {
		position: relative;
		width: 200px;
		height: 38px;
		margin: 0 auto;
		border-radius: 22px;
		background: rgba(0,0,0,0.2);
		text-decoration: none;
		line-height: 38px;
		text-align: center;
		font-size: 14px;
		font-family: 'Exo', sans-serif;
		font-weight: 500;
		letter-spacing: 0.05em;
		color: rgba(255,255,255,0.8);
		cursor: pointer;
		transition: 0.3s ease-in-out;

		&:hover {
			background: rgba(0,0,0,0.3);
			color: #d24a70;
		}
	}
}

@import "footer";
@import "404";

/* Mobile */
@media screen and (max-width: 767px) {
	body {
		background-position: center;
	}
	/* Intro */
	#intro-container {
		width: 100%;

		.polygon-mobile {
			display: block !important;
		}		
	}
	#polygons {
		height: auto !important;
		margin-bottom: 0 !important;

		.polygon-big {
			display: none !important;
		}
		.polygon-1 {
			display: none !important;
		}
		.polygon-2 {
			display: none !important;
		}
		.logo-big {
			position: relative !important;
			top: auto !important;
			left: auto !important;
			width: 100% !important;
			text-align: center !important;
			transform: scale(0.6) !important;
		}
	}
	.intro-text {
		margin-top: 60px !important;
		padding: 0 20px;
		font-size: 16px !important;
	}
	.scroll-down {
		bottom: 20px;
	}
	/* /Intro */

	/* They Mean Well */
	#tmw-base {
		padding-top: 50px;
	}
	#planet-base {
		width: 100%;
		transform: scale(0.7);

		.comet-nature {
			left: 10px !important;
		}
		.comet-slow {
			top: -20px !important;
			right: 30px !important;
		}
		.comet-speed {
			left: -60px !important;
		}
		.comet-tech {
			top: 130px !important;
			right: -60px !important;
		}
		.comet-lvlup {
			right: -10px !important;
		}
	}
	.section-info {
		width: 100%;
		padding: 0 20px;
		margin: 20px auto 0 auto;
		font-size: 16px !important;
	}
	.release-date {
		width: 100%;
		margin: 10px auto 0 auto;
		font-size: 15px;
	}
	h1 {
		font-size: 20px !important;
		margin-bottom: 20px;
	}
	.tmw-buttons-base {
		width: 100%;
		margin: 60px auto 0 auto;
		line-height: 0;
	}
	.btn-gplay {
		line-height: 58px;
	}
	.btn-gplay-disabled {
		line-height: 58px;
	}
	.btn-app-store {
		line-height: 58px;
	}
	.btn-app-store-disabled {
		line-height: 58px;
	}
	.btn-amazon {
		line-height: 58px;
	}
	.btn-amazon-disabled {
		line-height: 58px;
	}
	.btn-steam {
		line-height: 58px;
	}
	.btn-steam-disabled {
		line-height: 58px;
	}
	.video {
		width: calc(100% - 40px);
		height: 320px;
		margin: 60px auto 0 auto;
	}
	/* /They Mean Well */

	/* Shards of Memories */
	#som-base {
		padding: 50px 0 80px 0;
	}
	#shards-base {
		width: 100%;

		.som-logo {
			top: 80px !important;
			transform: translateX(-50%) scale(0.8) !important;
		}
		.silhouette {
			transform: translateX(-50%) scale(0.7) !important;
		}
	}
	.shard {
		transform: scale(0.7) !important;
	}
	.shard1 {
		top: 560px !important;
		left: -180px !important;
	}
	.shard2 {
		top: 740px !important;
		left: -20px !important;
	}
	.shard3 {
		top: 950px !important;
		right: 10px !important;
	}
	.shard4 {
		right: -80px !important;
	}
	.shard5 {
		right: -150px !important;
	}
	.som-buttons-base {
		width: 100%;
		margin: 60px auto 0 auto;
		line-height: 0;
	}
	/* /Shards of Memories */

	/* Contact */
	#contact {
		padding-bottom: 80px;
	}
	.contact-info {
		width: 100%;
		padding: 0 20px;
		font-size: 16px !important;
	}
	.contact-form {
		width: 100%;
		padding: 0 20px 0;

		.btn-send {
			height: 48px;
			line-height: 48px;
			border-radius: 30px;
			background: rgba(0,0,0,0.3);

			&:hover {
				background: rgba(0,0,0,0.5);
			}
		}
	}
	/* /Contact */
}

@media screen and (max-width: 360px) {
	.intro-text {
		margin-top: 40px !important;
		font-size: 15px !important;
	}
	.section-info {
		font-size: 15px !important;
		margin: 0 auto;
	}
	.release-date {
		font-size: 14px !important;
	}
	.polygon-mobile {
		img {
			transform: scale(0.9) !important;
		}
	}
	.logo-big {
		transform: scale(0.35) !important;
	}
	h1 {
		font-size: 18px !important;
	}
	#planet-base {
		transform: scale(0.6);
	}
	#tmw-base {
		padding-top: 0;
	}
	#som-base {
		padding: 0 0 60px 0;
	}
	.contact-info {
		font-size: 15px !important;
	}
}

@media screen and (max-width: 320px) {
	body {
		background-position: bottom;
	}
	.intro-text {
		font-size: 14px !important;
	}
	.section-info {
		font-size: 14px !important;
		margin: 0 auto;
	}
	.release-date {
		font-size: 13px !important;
	}
	.contact-info {
		font-size: 14px !important;
	}
}
/* /Mobile */

/* Tablet */
@media screen and (min-width: 768px) and (max-width: 1024px) {
	body {
		background-position: top;
	}
	/* Intro */
	#intro-container {
		width: 100%;
	}
	#polygons {
		margin-bottom: 60px !important;
	}
	.intro-text {
		padding: 0 40px;
		font-size: 16px !important;
	}
	/* /Intro */

	/* They Mean Well */
	#tmw-base {
		padding-top: 120px;
	}
	#planet-base {
		width: 100%;

		.comet-nature {
			left: 160px !important;
		}
		.comet-slow {
			top: 0 !important;
			right: 140px !important;
		}
		.comet-speed {
			left: 20px !important;
		}
		.comet-tech {
			right: 20px !important;
		}
	}
	.section-info {
		width: 100%;
		padding: 0 40px;
		margin: 80px auto 0 auto;
		font-size: 16px !important;
	}
	.release-date {
		width: 100%;
		margin: 20px auto 0 auto;
		font-size: 15px;
	}
	h1 {
		font-size: 20px !important;
		margin-bottom: 20px;
	}
	.tmw-buttons-base {
		width: 100%;
		margin: 60px auto 0 auto;
		line-height: 0;
	}
	.btn-gplay {
		line-height: 58px;
	}
	.btn-gplay-disabled {
		line-height: 58px;
	}
	.btn-app-store {
		line-height: 58px;
	}
	.btn-app-store-disabled {
		line-height: 58px;
	}
	.btn-amazon {
		line-height: 58px;
	}
	.btn-amazon-disabled {
		line-height: 58px;
	}
	.btn-steam {
		line-height: 58px;
	}
	.btn-steam-disabled {
		line-height: 58px;
	}
	.video {
		width: calc(100% - 60px);
		height: 480px;
		margin: 80px auto 0 auto;
	}
	/* /They Mean Well */

	/* Shards of Memories */
	#som-base {
		padding: 120px 0 80px 0;
	}
	#shards-base {
		width: 100%;
	}
	.shard1 {
		top: 460px !important;
		left: -50px !important;
	}
	.shard2 {
		left: 160px !important;
	}
	.shard3 {
		top: 805px !important;
		right: 180px !important;
	}
	.shard4 {
		top: 1210px !important;
		right: 90px !important;
	}
	.shard5 {
		top: 600px !important;
	}
	.som-buttons-base {
		width: 100%;
		margin: 60px auto 0 auto;
		line-height: 0;
	}
	/* /Shards of Memories */

	/* Contact */
	#contact {
		padding-bottom: 80px;
	}
	.container {
		width: 100% !important;
		padding: 0 30px !important;
	}
	.contact-info {
		width: 100%;
		font-size: 16px !important;
	}
	.contact-form {

		.btn-send {
			height: 48px;
			line-height: 48px;
			border-radius: 30px;
			background: rgba(0,0,0,0.3);

			&:hover {
				background: rgba(0,0,0,0.5);
			}
		}
	}
	/* /Contact */
}

@media screen and (min-width: 900px) and (max-width: 1169px) {
	/* Intro */
	#intro-container {
		width: 900px;
	}
	#shards-base {
		width: 100%;
	}
}
/* /Tablet */