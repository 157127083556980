nav {
	position: fixed;
	top: 50%;
	right: 30px;
	transform: translate3d(0,-50%,0);
	width: auto;
	height: 134px;
	opacity: 0;
	z-index: 10;

	.nav_item {
		position: relative;
		width: 38px;
		height: 38px;
		border-radius: 22px;
		background: rgba(255,255,255,0.1);
		line-height: 38px;
		text-align: center;
		margin: auto 0 10px auto;
		clear: both;
		overflow: hidden;
		cursor: pointer;
		transition: 0.6s ease;
		transition-delay: 0.3s;
		a {
			padding: 0;
		}
		.title {
			font-size: 14px;
			font-family: 'Exo', sans-serif;
			font-weight: 500;
			letter-spacing: 0.05em;
			color: rgba(255,255,255,0.8);
			white-space: nowrap;
			opacity: 0;
			transition: 0.4s ease;
			transition-delay: 0;
			&:hover {
				opacity: 1;
				transition: 0.6s ease;
				transition-delay: 0.6s;
			}
		}
		.chevron {
			position: absolute;
			top: 50%;
			left: 50%;
			transform: translate(-50%,-50%);
			font-size: 12px !important;
			color: rgba(255,255,255,0.1);
			transition: 0.3s ease;
			transition-delay: 0.6s;

			i {
				position: relative;
				top: 1px;
				right: 1px;
			}
			&:hover {
				opacity: 0;
				transition: 0.4s ease;
			}
		}
		.hidden {
			opacity: 0;
			transition: 0.4s ease;
		}
		&.active, &:hover, &:focus {
			width: 200px;
			height: 38px;
			border-radius: 22px;
			opacity: 1;
			transition: 0.6s ease;
			.title {
				opacity: 1;
				transition: 0.6s ease;
				transition-delay: 0.4s;
			}
			.chevron {
				opacity: 0;
				transition: 0.4s ease;
			}
			a:hover, a:focus {
				background-color: rgba(255, 255, 255, 0.1) !important;
			}
		}
		a:hover, a:focus,a:active {
			background-color: rgba(255, 255, 255, 0.1) !important;
		}
	}
	.nav_item {
		z-index: 11;
		&.tmw {
			z-index: 13;
		}
	}
}

.nav-sticky {
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 60px;
	background: rgba(0,0,0,0.7);
	display: none;
	z-index: 999999;
	transition: 0.5s ease;
}

.gradient-bar {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 4px;
	background-image: -moz-linear-gradient( 0deg, rgb(189,69,87) 0%, rgb(109,42,104) 100%);
	background-image: -webkit-linear-gradient( 0deg, rgb(189,69,87) 0%, rgb(109,42,104) 100%);
	background-image: -ms-linear-gradient( 0deg, rgb(189,69,87) 0%, rgb(109,42,104) 100%);
}

.logo {
	position: absolute;
	top: 0;
	left: 20px;
	height: 60px;
	line-height: 60px;
	cursor: pointer;
	opacity: 0.8;
	transition: 0.3s ease-in-out;

	&:hover {
		opacity: 0.6;
	}
	img {
		width: 180px;
	}
}

.nav-responsive {
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 60px;
	background: rgba(0,0,0,0.7);
	display: none;
	z-index: 999999;
}

.menu-toggle {
	position: fixed;
	top: 0;
	right: 0;
	width: 60px;
	height: 60px;
	text-align: center;
	line-height: 58px;
	float: right;
	opacity: 0.8;
	transition: 0.5s ease;
	z-index: 99999999;
	display: none;

	img {
		width: 20px;
	}
}

.rotate {
	transform: rotate(-90deg);
}

.menu-responsive {
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100vh;
	background: rgba(0,0,0,1);
	display: none;
	z-index: 9999999;

	.menu-center {
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%,-50%);
		width: 100%;
		height: auto;
	}
	ul {
		width: 100%;
		list-style: none;
		margin: 0 auto;
		padding: 0;
		font-size: 14px;
		font-family: 'Exo', sans-serif;
		font-weight: 500;
		letter-spacing: 0.05em;
		color: rgba(255,255,255,0.8);

		li {
			width: 100%;
			height: 60px;
			background: rgba(255,255,255,0);
			border-top: 1px solid rgba(255,255,255,0.1);
			line-height: 60px;
			text-align: center;

			&:hover {
				color: #d24a70;
			}
		}
	}
	.social-btns {
		position: relative;
		width: 174px;
		height: auto;
		margin: 20px auto 0 auto;
	}
	
	.btn-social {
		position: relative;
		width: 48px;
		height: 48px;
		margin: 0 5px;
		background: rgba(255,255,255,0.1);
		line-height: 48px;
		border-radius: 50%;
		text-align: center;
		font-size: 18px;
		color: rgba(255,255,255,0.8);
		cursor: pointer;
		transition: 0.3s ease-in-out;
		float: left;

		&:hover {
			color: #d24a70;
		}
	}
	.menu-logo {
		position: absolute;
		top: 0;
		left: 20px;
		height: 60px;
		line-height: 60px;
		cursor: pointer;
		opacity: 0.8;

		&:hover {
			opacity: 0.6;
		}
		img {
			width: 180px;
		}
	}
}

@media only screen and (max-width: 1023px) {
	nav {
		display: none;
	}
	.menu-toggle {
		display: block;
	}
}