footer {
	width: 100%;
	height: 60px;
	background: rgba(0,0,0,0.2);

	.container {
		height: 100%;
	}
	.copyright {
		width: auto;
		height: 100%;
		line-height: 60px;
		font-size: 14px;
		font-family: 'Exo', sans-serif;
		letter-spacing: 0.05em;
		color: rgba(255,255,255,0.1);
		text-align: left;
		float: left;
	}

	.social {
		width: auto;
		height: 100%;
		line-height: 60px;
		font-size: 18px;
		color: rgba(255,255,255,0.1);
		float: right;

		a {
			text-decoration: none;
			color: rgba(255,255,255,0.1);
			margin-left: 30px;
			transition: 0.2s ease;

			&:hover {
				color: rgba(255,255,255,1);
			}
		}
	}
}

@media only screen and (max-width: 767px) {
	footer {
		background: rgba(0,0,0,0.3);
	}
	.copyright {
		width: 100% !important;
		text-align: center !important;
		color: rgba(255,255,255,0.3) !important;
	}
	.social {
		display: none;
	}
}

@media screen and (max-width: 400px) {
	footer {
		height: 80px;
	}
	.copyright {
		padding: 20px 20px !important;
		line-height: 20px !important;
	}
}