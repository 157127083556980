#base-404 {
	position: relative;
	width: 100%;
	height: 100vh;

	.content-404 {
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%,-50%);
		width: 900px;
		height: auto;

		.tardi-404 {
			position: relative;
			width: 209px;
			height: auto;
			margin: 0 auto;
		}
		
		.section-info {
			margin: 60px auto 0 auto;
			a {
				text-decoration: none;
				color: #d24a70;
			}
		}
	}
}