/* Intro Animations */

.polygon-big.go {
    animation-name: polygon-big;
    animation-duration: 1.5s;
    animation-timing-function: cubic-bezier(0.360, 0.100, 0.095, 0.995);
    animation-iteration-count: 1;
    animation-fill-mode: forwards;
}

@keyframes polygon-big { 
    0% {
        transform: scale(0.7) translate(400px,200px);
    }
    100% {
        transform: scale(1) translate(0,0);
        opacity: 1; 
    }
}

.polygon-mobile.go {
    animation-name: polygon-mob;
    animation-duration: 1.5s;
    animation-timing-function: ease-in-out;
    animation-iteration-count: 1;
    animation-delay: 1.2s;
    animation-fill-mode: forwards;
}

@keyframes polygon-mob { 
    0% {
    }
    100% {
        opacity: 1; 
    }
}

.polygon-1.go {
    animation-name: polygon-1;
    animation-duration: 1.5s;
    animation-timing-function: cubic-bezier(0.360, 0.100, 0.095, 0.995);
    animation-iteration-count: 1;
    animation-delay: 0.2s;
    animation-fill-mode: forwards;
}

@keyframes polygon-1 { 
    0% {
        transform: scale(0.7) translate(200px,100px);
    } 
    100% {
        transform: scale(1) translate(0,0);
        opacity: 1; 
    }
}

.polygon-2.go {
    animation-name: polygon-2;
    animation-duration: 1.3s;
    animation-timing-function: cubic-bezier(0.360, 0.100, 0.095, 0.995);
    animation-iteration-count: 1;
    animation-delay: 0.4s;
    animation-fill-mode: forwards;
}

@keyframes polygon-2 { 
    0% {
        transform: scale(0.7) translate(100px,50px);
    } 
   100% {
        transform: scale(1) translate(0,0);
        opacity: 1; 
    }
}

.logo-big.go {
    animation-name: logo-big;
    animation-duration: 1.5s;
    animation-timing-function: ease-in-out;
    animation-iteration-count: 1;
    animation-delay: 1.2s;
    animation-fill-mode: forwards;
}

@keyframes logo-big { 
    0% {
        opacity: 0; 
    }
   100% {
        opacity: 1; 
    }
}

.menu-toggle {
    animation-name: menu;
    animation-duration: 1.5s;
    animation-timing-function: ease-in-out;
    animation-iteration-count: 1;
    animation-fill-mode: forwards;
}

@keyframes menu { 
    0% {
        opacity: 0; 
    }
   100% {
        opacity: 1; 
    }
}

.intro-text.go {
    animation-name: intro-text;
    animation-duration: 1.5s;
    animation-timing-function: ease-in-out;
    animation-iteration-count: 1;
    animation-delay: 1.2s;
    animation-fill-mode: forwards;
}

@keyframes intro-text { 
    0% {
        opacity: 0; 
    }
   100% {
        opacity: 1; 
    }
}

#social.go {
    animation-name: social;
    animation-duration: 0.8s;
    animation-timing-function: ease-in-out;
    animation-iteration-count: 1;
    animation-delay: 2.5s;
    animation-fill-mode: forwards;
}

@keyframes social { 
    0% {
        opacity: 0; 
    }
   100% {
        opacity: 1; 
    }
}

nav.go {
    animation-name: navigation;
    animation-duration: 0.8s;
    animation-timing-function: ease-in-out;
    animation-iteration-count: 1;
    animation-delay: 2.5s;
    animation-fill-mode: forwards;
}

@keyframes navigation { 
    0% {
        opacity: 0; 
    }
   100% {
        opacity: 1; 
    }
}

/* /Intro Animations */

/* They Mean Well Animations */

.comet-speed.go {
    animation-name: comet-speed;
    animation-duration: 2s;
    animation-timing-function: cubic-bezier(0.000, 1.080, 0.340, 1.000);
    animation-iteration-count: 1;
    animation-delay: 0.8s;
    animation-fill-mode: forwards;
}

@keyframes comet-speed { 
    0% {
        transform: translate(-320px,150px);
    } 
    100% {
        transform: translate(0,0);
        opacity: 1; 
    }
}

.comet-tech.go {
    animation-name: comet-tech;
    animation-duration: 2.3s;
    animation-timing-function: cubic-bezier(0.000, 1.080, 0.340, 1.000);
    animation-iteration-count: 1;
    animation-delay: 1s;
    animation-fill-mode: forwards;
}

@keyframes comet-tech { 
    0% {
        transform: translate(220px,-100px);
    } 
    100% {
        transform: translate(0,0);
        opacity: 1; 
    }
}

.comet-nature.go {
    animation-name: comet-nature;
    animation-duration: 3s;
    animation-timing-function: cubic-bezier(0.000, 1.080, 0.340, 1.000);
    animation-iteration-count: 1;
    animation-delay: 1s;
    animation-fill-mode: forwards;
}

@keyframes comet-nature { 
    0% {
        transform: translate(-200px,-200px);
    } 
    100% {
        transform: translate(0,0);
        opacity: 1; 
    }
}

.comet-slow.go {
    animation-name: comet-slow;
    animation-duration: 2.5s;
    animation-timing-function: cubic-bezier(0.030, 1.080, 0.340, 1.000);
    animation-iteration-count: 1;
    animation-delay: 1.2s;
    animation-fill-mode: forwards;
}

@keyframes comet-slow { 
    0% {
        transform: translate(280px,-200px);
    } 
    100% {
        transform: translate(0,0);
        opacity: 1; 
    }
}

.comet-lvlup.go {
    animation-name: comet-lvlup;
    animation-duration: 2.5s;
    animation-timing-function: cubic-bezier(0.000, 1.080, 0.340, 1.000);
    animation-iteration-count: 1;
    animation-delay: 1.1s;
    animation-fill-mode: forwards;
}

@keyframes comet-lvlup { 
    0% {
        transform: translate(160px,240px);
    } 
    100% {
        transform: translate(0,0);
        opacity: 1; 
    }
}

.tmw-title.go {
    animation-name: tmw-title;
    animation-duration: 1.5s;
    animation-timing-function: ease-in-out;
    animation-iteration-count: 1;
    animation-delay: 2.9s;
    animation-fill-mode: forwards;
}

@keyframes tmw-title { 
    0% {
        opacity: 0; 
    } 
    100% {
        opacity: 1; 
    }
}

#tmw-base .section-info.go {
    animation-name: section-info;
    animation-duration: 1.5s;
    animation-timing-function: ease-in-out;
    animation-iteration-count: 1;
    animation-delay: 2.9s;
    animation-fill-mode: forwards;
}

@keyframes section-info { 
    0% {
        opacity: 0; 
    } 
    100% {
        opacity: 1; 
    }
}

#tmw-base .tmw-buttons-base.go {
    animation-name: tmw-buttons;
    animation-duration: 0.8s;
    animation-timing-function: ease-in-out;
    animation-iteration-count: 1;
    animation-delay: 4.1s;
    animation-fill-mode: forwards;
}

@keyframes tmw-buttons { 
    0% {
        opacity: 0; 
    } 
    100% {
        opacity: 1; 
    }
}

#tmw-base .release-date.go {
    animation-name: tmw-release;
    animation-duration: 0.8s;
    animation-timing-function: ease-in-out;
    animation-iteration-count: 1;
    animation-delay: 4.1s;
    animation-fill-mode: forwards;
}

@keyframes tmw-release { 
    0% {
        opacity: 0; 
    } 
    100% {
        opacity: 1; 
    }
}

#tmw-base .video.go {
    animation-name: tmw-video;
    animation-duration: 0.8s;
    animation-timing-function: ease-in-out;
    animation-iteration-count: 1;
    animation-delay: 4.1s;
    animation-fill-mode: forwards;
}

@keyframes tmw-video { 
    0% {
        opacity: 0; 
    } 
    100% {
        opacity: 1; 
    }
}

/* /They Mean Well Animations */

/* Shards of Memories Animations */

.shard1.go {
    animation-name: shard1;
    animation-duration: 3s;
    animation-timing-function: ease-in-out;
    animation-iteration-count: 1;
    animation-fill-mode: forwards;
}

@keyframes shard1 { 
    0% {
        transform: scale(2.4) translate(-400px,-400px) rotate(-120deg);
    } 
    20% {
        opacity: 0; 
    }
    100% {
        transform: scale(1) translate(0,0) rotate(0deg);
        opacity: 1; 
    }
}

.shard2.go {
    animation-name: shard2;
    animation-duration: 3s;
    animation-timing-function: ease-in-out;
    animation-iteration-count: 1;
    animation-fill-mode: forwards;
}

@keyframes shard2 { 
    0% {
        transform: scale(0.7) translate(-30px,400px);
    } 
    30% {
        opacity: 0; 
    }
    100% {
        transform: scale(1) translate(0,0);
        opacity: 1; 
    }
}

.shard3.go {
    animation-name: shard3;
    animation-duration: 3s;
    animation-timing-function: ease-in-out;
    animation-iteration-count: 1;
    animation-fill-mode: forwards;
}

@keyframes shard3 { 
    0% {
        transform: scale(2) translate(600px,-200px) rotate(180deg);
    } 
    30% {
        opacity: 0; 
    }
    100% {
        transform: scale(1) translate(0,0) rotate(0);
        opacity: 1; 
    }
}

.shard4.go {
    animation-name: shard4;
    animation-duration: 3s;
    animation-timing-function: ease-in-out;
    animation-iteration-count: 1;
    animation-fill-mode: forwards;
}

@keyframes shard4 { 
    0% {
        transform: scale(1.7) translate(400px,300px) rotate(-180deg);
    } 
    20% {
        opacity: 0; 
    }
    100% {
        transform: scale(1) translate(0,0) rotate(0deg);
        opacity: 1; 
    }
}

.shard5.go {
    animation-name: shard5;
    animation-duration: 2.6s;
    animation-timing-function: ease-in-out;
    animation-iteration-count: 1;
    animation-fill-mode: forwards;
    animation-delay: 0.4s;
}

@keyframes shard5 { 
    0% {
        transform: scale(1.7) translate(400px,-160px) rotate(-180deg);
    } 
    20% {
        opacity: 0; 
    }
    100% {
        transform: scale(1) translate(0,0) rotate(0deg);
        opacity: 1; 
    }
}

.som-logo.go {
    animation-name: som-logo;
    animation-duration: 1.5s;
    animation-timing-function: ease-in-out;
    animation-iteration-count: 1;
    animation-delay: 3s;
    animation-fill-mode: forwards;
}

@keyframes som-logo { 
    0% {
        opacity: 0; 
    } 
    100% {
        opacity: 1; 
    }
}

#som-base .section-info.go {
    animation-name: section-info;
    animation-duration: 1.5s;
    animation-timing-function: ease-in-out;
    animation-iteration-count: 1;
    animation-delay: 3s;
    animation-fill-mode: forwards;
}

#som-base .som-buttons-base.go {
    animation-name: som-buttons;
    animation-duration: 0.8s;
    animation-timing-function: ease-in-out;
    animation-iteration-count: 1;
    animation-delay: 4.2s;
    animation-fill-mode: forwards;
}

@keyframes som-buttons { 
    0% {
        opacity: 0; 
    } 
    100% {
        opacity: 1; 
    }
}

#som-base .release-date.go {
    animation-name: som-release;
    animation-duration: 0.8s;
    animation-timing-function: ease-in-out;
    animation-iteration-count: 1;
    animation-delay: 4.2s;
    animation-fill-mode: forwards;
}

@keyframes som-release { 
    0% {
        opacity: 0; 
    } 
    100% {
        opacity: 1; 
    }
}

#som-base .video.go {
    animation-name: som-video;
    animation-duration: 0.8s;
    animation-timing-function: ease-in-out;
    animation-iteration-count: 1;
    animation-delay: 4.2s;
    animation-fill-mode: forwards;
}

@keyframes som-video { 
    0% {
        opacity: 0; 
    } 
    100% {
        opacity: 1; 
    }
}

/* /Shards of Memories Animations */

/* Contact Form Animations */

#contact .contact-info.go {
    animation-name: contact-info;
    animation-duration: 1.5s;
    animation-timing-function: ease-in-out;
    animation-iteration-count: 1;
    animation-delay: 0.5s;
    animation-fill-mode: forwards;
}

@keyframes contact-info { 
    0% {
        opacity: 0; 
    } 
    100% {
        opacity: 1; 
    }
}

#contact .contact-form.go {
    animation-name: contact-form;
    animation-duration: 0.8s;
    animation-timing-function: ease-in-out;
    animation-iteration-count: 1;
    animation-delay: 1s;
    animation-fill-mode: forwards;
}

@keyframes contact-form { 
    0% {
        opacity: 0; 
    } 
    100% {
        opacity: 1; 
    }
}

/* /Contact Form Animations */